<template>
  <div sub-menu :class="[scrolled, hidden]">
    <ul>
      <li><router-link to="/cartridge/skin"><img src="/img/common/ico-header-skin.svg" alt="">Skin</router-link></li>
      <li><router-link to="/cartridge/air-handling"><img src="/img/common/ico-header-airHandling.svg" alt="">Air Handling</router-link></li>
      <li><router-link to="/cartridge/ict"><img src="/img/common/ico-header-ict.svg" alt="">ICT</router-link></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'SubMenu',
  data() {
    return {
    };
  },
  watch: {
    $route: 'reset',
  },
  computed: {
    scrolled() {
      return this.$store.state.browser.scrollTop > 10 ? 'scrolled' : '';
    },
    hidden() {
      return this.$store.state.browser.scrollTop > 250 ? 'hidden' : '';
    },
  },
  methods: {
    reset() {
    },
  },
  mounted() {
    this.reset();
  }
};
</script>

<style lang="less">
@import "~@/less/proj.less";

.drop-shadow() { box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.12); }
[sub-menu] { .block; .sticky; .lt; .pt(60); .wf; .bgc(#fff); .t-y(0); transition: all 0.6s ease; z-index: 2;
  &.scrolled { .t-y(-60); }
  &.hidden { .t-y(-120%); }
  ul { .grid(3); .drop-shadow; .-t(#eee);
    a { .rel; .block; .tc; .fs(16,24); .o(.5); .p(18,0);
      &.router-link-active { .o(1);
        &:after { .cnt; .abs; .lb; .r(0); .h(2); .bgc(#000); }
      }
    }
    img { .hide; }
  }
}

@media (min-width: @screen-ds-min) {

  [sub-menu] { .pt(80);
    &.scrolled { .t-y(-80); }
    &.hidden { .t-y(-100%); }
    ul { .flex; .justify-center; .drop-shadow; .-t;
      li { .rel; }
      li + li { .ml(56); }
      a { .block; .tc; .fs(14,20); .p(18,0); .w(148); .o(.5);
        &:hover { .o(1); }
        &.router-link-active { .o(1);
          &:after { .cnt; .abs; .lb(13,0); .r(13); .h(2); .bgc(#000); }
        }
      }
      img { .block; .w(48); .m(0,auto,2); }
    }
  }
}
</style>
