<template>
  <main skin class="prod">
    <sub-menu />
    <p-section class="hero">
      <div class="holder" v-prx:sticky>
        <div class="bg" v-prx:progress="{ r:[3,6], o:[0,1] }"></div>
        <h2 v-prx:progress="{ r:[3,6], o:[1,0] }">스킨 카트리지</h2>
        <p v-prx:progress="{ r:[3,6], o:[1,0] }">실시간 일사량 조절</p>
        <img src="/img/cartridge/skin-hero.png" alt="" v-prx:progress="{
            MS: { r:[4,10], ty:[0,-180], o:[1,0] },
            TL: { r:[4,10], ty:[0,-500], o:[1,0] }
        }">
        <div class="overview" v-prx:progress="{ r:[6,10], o:[0,1], ty:[80,0] }">
          <p v-prx:outro="{ r:[1,12], o:[1,0], ty:[0,160] }">스킨 카트리지</p>
          <p v-prx:outro="{ r:[1,12], o:[1,0], ty:[0,160] }">
            실내로 유입되는 일사량을 조절하는
            건축 스킨입니다. 일사량을 자동으로
            세밀하게 조절하며, 모든 과정은 전용
            OS를 통해 간편하게 이루어집니다.
            스킨의 재질과 색상을 직접 선택하여
            건물 외벽을 개성있게 디자인 할 수
            있습니다.</p>
        </div>
      </div>
    </p-section>
    <p-section class="feature">
      <div class="holder" v-prx:sticky>
        <ul v-prx:progress="{ r:[1,11], pan:[0,100] }">
          <li>
            <div class="inner-wrap">
              <img src="/img/cartridge/skin-feature-ico-1.svg" alt="" class="ico">
              <p>섬세한 <br>스킨 컨트롤</p>
              <p>시간대별 조도를 반영하여 일사량을
                조절합니다. 스킨 상부와 하부의
                독립적인 작동을 통해 섬세한 제어가 가능합니다.</p>
              <div class="video">
                <video src="/img/cartridge/skin-feature-video-1.l.mp4" autoplay muted playsinline loop class="hidden-ml-down"></video>
                <video src="/img/cartridge/skin-feature-video-1.s.mp4" autoplay muted playsinline loop class="hidden-tp-up"></video>
              </div>
            </div>
          </li>
          <li>
            <div class="inner-wrap">
              <img src="/img/cartridge/skin-feature-ico-2.svg" alt="" class="ico">
              <p>간편한 <br>조도 제어</p>
              <p>사용자는 모바일 기기로 일사량을 직접 제어할 수 있습니다. 세밀한 스킨 각도 조절로 조도 환경 맞춤화가 가능합니다.</p>
              <div class="video">
                <video src="/img/cartridge/skin-feature-video-2.l.mp4" autoplay muted playsinline loop class="hidden-ml-down"></video>
                <video src="/img/cartridge/skin-feature-video-2.s.mp4" autoplay muted playsinline loop class="hidden-tp-up"></video>
              </div>
            </div>
          </li>
          <li>
            <div class="inner-wrap">
              <img src="/img/cartridge/skin-feature-ico-3.svg" alt="" class="ico">
              <p>다양한 <br>스타일 연출</p>
              <p>다채로운 스킨 옵션 중 원하는 소재와
                색상을 선택하여 건물 외벽을 자유롭게
                커스터마이징 할 수 있습니다.</p>
              <div class="video">
                <video src="/img/cartridge/skin-feature-video-3.l.mp4" autoplay muted playsinline loop class="hidden-ml-down"></video>
                <video src="/img/cartridge/skin-feature-video-3.s.mp4" autoplay muted playsinline loop class="hidden-tp-up"></video>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </p-section>
    <p-section class="structure">
      <div class="holder" v-prx:sticky>
        <h3>간편한 <br class="hidden-tp-up">설치와 교체</h3>
        <img src="/img/cartridge/skin-structure.png" alt="" class="hidden-tl-up">
        <img src="/img/cartridge/skin-structure.l.png" alt="" class="hidden-tp-down">
        <dl>
          <dt>① 외부 프레임_옵션1</dt>
          <dd>깃털 타입 적용</dd>
          <dt>② 외부 프레임_옵션2</dt>
          <dd>글래스 타입 적용</dd>
          <dt>③ 스킨 카트리지</dt>
          <dd>친환경 건축 스킨</dd>
          <dt>④ FIT 프레임</dt>
          <dd>내부 스킨을 포함한 외장 함체</dd>
          <dt>⑤ 가이드 프레임</dt>
          <dd>FIT 프레임 지지/기반</dd>
        </dl>
        <p>직관적인 구조를 통해 카트리지를 간편하게 설치하고 손쉽게 교체할 수 있습니다. 예기치 않은 변화와 다양한 요구에 유연하게 대응합니다.</p>
      </div>
    </p-section>
    <section class="custom">
      <div class="inner-wrap">
        <h3>스킨 커스터마이징</h3>
        <div class="viewer">
          <p class="hidden-tl-up">스킨 / 컬러</p>
          <div class="control">
            <div class="slider">
              <p class="hidden-tp-down">스킨</p>
              <Slider navigation slidesPerView="1" @changed="onCustomSkin">
                <div v-for="i in 4" :key="i">
                  <div class="img"><img :src="`/img/cartridge/skin-spec-${i}.png`" alt=""></div>
                  <p>{{ ['FABRIC','STRING','METAL','FEATHER'][i-1] }}</p>
                </div>
              </Slider>
            </div>
            <div class="slider">
              <p class="hidden-tp-down">컬러</p>
              <Slider navigation pagination pagination-type="fraction" @changed="onCustomColor" :key="sliderKey">
                <div v-for="(color, i) in colors" :key="color">
                  <div class="img"><img :src="`/img/cartridge/skin-custom-color-${skinNum+1}${i+1}.png`" alt=""></div>
                  <p>{{ color }}</p>
                </div>
              </Slider>
              <p class="hidden-tp-down">
                <span v-if="skinNum === 0 && colorNum < 6">POROSITY : 4% BLOCKS UP TO 96% OF THE SUN’S HEAT <br>QUALITY : ISO 9001 / ENVIRONMENT : ISO 14001 / ENERGY : ISO 50001</span>
                <span v-if="skinNum === 0 && colorNum >= 6">POROSITY : 28% BLOCKS UP TO 78% OF THE SUN'S HEAT <br>QUALITY : ISO 9001 ENVIRONMENT : ISO 14001 ENERGY : ISO 50001 <br>VARIOUS COLOR VARIOUS COLOR SPRING</span>
                <span v-if="skinNum === 1">MATERIAL : POLYESTER DIAMETER : Ø4 THE NUMBER OF STRIPS : 10</span>
                <span v-if="skinNum === 2">MATERIAL : AL A5052 THICKNESS : 2MM</span>
                <span v-if="skinNum === 3">MATERIAL : AL A6063-T5 EXTRUSION</span>
              </p>
            </div>
          </div>
          <p class="result-txt hidden-tl-up">
            <span v-if="skinNum === 0 && colorNum < 6">POROSITY : 4% BLOCKS UP TO 96% OF THE SUN’S HEAT <br>QUALITY : ISO 9001 / ENVIRONMENT : ISO 14001 / ENERGY : ISO 50001</span>
            <span v-if="skinNum === 0 && colorNum >= 6">POROSITY : 28% BLOCKS UP TO 78% OF THE SUN'S HEAT <br>QUALITY : ISO 9001 ENVIRONMENT : ISO 14001 ENERGY : ISO 50001 <br>VARIOUS COLOR VARIOUS COLOR SPRING</span>
            <span v-if="skinNum === 1">MATERIAL : POLYESTER DIAMETER : Ø4 THE NUMBER OF STRIPS : 10</span>
            <span v-if="skinNum === 2">MATERIAL : AL A5052 THICKNESS : 2MM</span>
            <span v-if="skinNum === 3">MATERIAL : AL A6063-T5 EXTRUSION</span>
          </p>
          <img class="result" :src="`/img/cartridge/skin-custom-result-${skinNum+1}${colorNum+1}.png`" alt="">
        </div>
      </div>
    </section>
    <section class="spec">
      <div class="inner-wrap">
        <h3>제품 사양</h3>
        <ul>
          <li>
            <img src="/img/cartridge/skin-spec-1.png">
            <p>페브릭 스킨</p>
            <dl>
              <dt>기본 사양</dt>
              <dd><b>높이</b> 4,177mm</dd>
              <dd><b>너비</b> 1,216mm</dd>
              <dd><b>무게</b> 48.1kg</dd>
            </dl>
            <dl>
              <dt>모터</dt>
              <dd><b>Motor</b> DC</dd>
              <dd><b>Supply voltage</b> 24V</dd>
              <dd><b>Nominal current</b> 1A</dd>
              <dd><b>Nominal speed</b> 2,920RPM</dd>
              <dd><b>Nominal torque</b> 4.1 N.cm</dd>
              <dd><b>Output power</b> 15 Watts</dd>
              <dd><b>IP</b> IP65</dd>
            </dl>
          </li>
          <li>
            <img src="/img/cartridge/skin-spec-2.png">
            <p>스트링 스킨</p>
            <dl>
              <dt>기본 사양</dt>
              <dd><b>높이</b> 4,177mm</dd>
              <dd><b>너비</b> 1,216mm</dd>
              <dd><b>무게</b> 57.4kg</dd>
            </dl>
            <dl>
              <dt>모터</dt>
              <dd><b>Motor</b> DC</dd>
              <dd><b>Supply voltage</b> 24V</dd>
              <dd><b>Nominal current</b> 1A</dd>
              <dd><b>Nominal speed</b> 2,920RPM</dd>
              <dd><b>Nominal torque</b> 4.1 N.cm</dd>
              <dd><b>Output power</b> 15 Watts</dd>
              <dd><b>IP</b> IP65</dd>
            </dl>
          </li>
          <li>
            <img src="/img/cartridge/skin-spec-3.png">
            <p>메탈 스킨</p>
            <dl>
              <dt>기본 사양</dt>
              <dd><b>높이</b> 4,121mm</dd>
              <dd><b>너비</b> 1,183mm</dd>
              <dd><b>무게</b> 66.1kg</dd>
            </dl>
            <dl>
              <dt>모터</dt>
              <dd><b>Motor</b> DC</dd>
              <dd><b>Supply voltage</b> 24V</dd>
              <dd><b>Nominal current</b> 1A</dd>
              <dd><b>Nominal speed</b> 2,920RPM</dd>
              <dd><b>Nominal torque</b> 4.1 N.cm</dd>
              <dd><b>Output power</b> 15 Watts</dd>
              <dd><b>IP</b> IP65</dd>
            </dl>
          </li>
          <li>
            <img src="/img/cartridge/skin-spec-4.png">
            <p>깃털 스킨</p>
            <dl>
              <dt>기본 사양</dt>
              <dd><b>높이</b> 4,177mm</dd>
              <dd><b>너비</b> 1,480mm</dd>
              <dd><b>무게</b> 122kg</dd>
            </dl>
            <dl>
              <dt>모터</dt>
              <dd><b>Motor</b> BLDC</dd>
              <dd><b>Supply voltage</b> 24V</dd>
              <dd><b>Nominal current</b> 6.5A</dd>
              <dd><b>Nominal speed</b> 3,220RPM</dd>
              <dd><b>Nominal torque</b> 31 N.cm</dd>
              <dd><b>Output power</b> 156 Watts</dd>
              <dd><b>IP</b> IP54</dd>
            </dl>
          </li>
        </ul>
      </div>
    </section>
  </main>
</template>
<script>
import Slider from '@/views/components/Slider.vue';
import SubMenu from '@/views/common/SubMenu.vue';

export default {
  name: 'CartridgeSkin',
  components: { SubMenu, Slider },
  data() {
    return {
      skinNum: 0,
      colorNum: 0,
      sliderKey: 0,
      customColor: [
          ['White','Gold','havana Brown','beetle','deep blue','deep black','grey','pumkin','glowing red','sandy beige','spring green','midnight blue'],
          ['White','grey'],
          ['silver'],
          ['silver','Brown'],
      ],
    };
  },
  metaInfo() {
    return {
      title: 'FIT Cartridge',
      meta: [
        { vmid: 'title', content: 'FIT Cartridge', },
        { vmid: 'description', content: 'FIT Cartridge는 예기치 않은 변화와 다양한 요구에 유연하게 대응하며 다양한 카트리지 디자인을 통해 건물의 아이덴티티 제공합니다.', },
        { vmid: 'image', content: `${process.env.VUE_APP_HOST}/img/og.png`, },
        { vmid: 'path', content: this.$route.path },
      ],
    }
  },
  computed: {
    colors() {
      return this.customColor[this.skinNum];
    },
  },
  methods: {
    onCustomSkin(n) {
      this.skinNum = n;
      this.colorNum = 0;
      this.sliderKey++;
    },
    onCustomColor(n) {
      this.colorNum = n;
    },
  },
  mounted() {
    this.onCustomSkin(0);
  }
};
</script>

<style lang="less">
@import "~@/less/proj.less";
@import "~@/less/prod.less";

[skin].prod {
  .spec {
    li {
      dl { .grid(2, 24);
        dt { grid-column: 1 / span 2; }
      }
    }
  }
}

@media (min-width: @screen-tp-min) {
  [skin].prod {
  }
}
@media (min-width: @screen-tl-min) {
  [skin].prod {
    .spec {
      ul { .grid(4); grid-column-gap: 0; }
      li {
        dl { .grid(1); grid-row-gap: 32px;
          dt { grid-column: 1; }
        }
      }
    }
    .structure {
      img {  }
    }
  }
}
@media (min-width: @screen-ds-min) {
  [skin].prod {
  }
}
@media (min-width: @screen-dl-min) {
  [skin].prod {
  }
}
</style>
